import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import NumbersIcon from "@mui/icons-material/Numbers";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import GavelIcon from "@mui/icons-material/Gavel";

export default function ContactInfo() {
  return (
    <div className="container flex-center flex-col">
      <h1 className="x-large text-center">Kontaktné informácie</h1>
      <div className="contact">
        <ul>
          <li className="lead">
            <BadgeIcon fontsize="large" />
            <span className="mx-1">
              Meno: Mgr. Viera Veronika Fitzeková - Prima Media Prešov
            </span>
          </li>
          <li className="lead">
            <AlternateEmailIcon ontSize="large" />
            <a
              href="mailto:fitzekova@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="mx-1">Email: fitzekova@gmail.com</span>
            </a>
          </li>
          <li className="lead">
            <PhoneIphoneIcon onSize="large" />
            <a href="tel:+421908994585" target="_blank" rel="noreferrer">
              <span className="mx-1">Mobil: +421 908 994 585</span>
            </a>
          </li>
          <li className="lead">
            <HomeIcon fontSize="large" />
            <span className="mx-1">Adresa: Mukačevská 30, 080 01 Prešov</span>
          </li>
          <li className="lead">
            <BusinessIcon fontSize="large" />
            <span className="mx-1">IČO: 40150437</span>
          </li>
          <li className="lead">
            <CorporateFareIcon fontSize="large" />
            <span className="mx-1">DIČ: 1021025038</span>
          </li>
          <li className="lead">
            <AccountBalanceIcon fontSize="large" />
            <span className="mx-1">
              Bankové spojenie: Slovenská sporiteľňa, a. s.
            </span>
          </li>
          <li className="lead">
            <NumbersIcon fontSize="large" />
            <span className="mx-1">IBAN: SK63 0900 0000 0051 5217 0650</span>
          </li>
          <li className="lead m-0">
            <GavelIcon fontSize="large" />
            <span className="mx-1">Zápis v: </span>
          </li>
          <ul className="mx-3">
            <li className="lead m-0">
              Okresný úrad Prešov, odbor živnostenského podnikania
            </li>
            <li className="lead m-0">OU-PO-OZP1-2018/046979-2</li>
            <li className="lead m-0">Č. živnostenského registra: 707-20441</li>
          </ul>
        </ul>
      </div>
    </div>
  );
}
