import React from "react";
import MasonryImageList from "../layout/MasonryImageList";
import useFetch from "../../hooks/useFetch";
import Spinner from "../sections/Spinner";
import ReactMarkdown from "react-markdown";

export default function AboutMe() {
  const { loading, error, estate } = useFetch(`/api/aboutme`);
  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }
  return (
    <section id="section-c" className="container text-center">
      <div className="about-me">
        <div className="about-me-text">
          <h2 className="x-large">O mne</h2>
          <ReactMarkdown className="lead">
            {estate && estate.attributes.text}
          </ReactMarkdown>
        </div>
        <div className="about-me-photos">
          <MasonryImageList />
        </div>
      </div>
    </section>
  );
}
