import React from "react";
import useFetch from "../../hooks/useFetch";
import Spinner from "../sections/Spinner";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

export default function CourseDetails() {
  const { id } = useParams();
  const {
    loading,
    error,
    estate: { attributes },
  } = useFetch(`/api/kurzs/${id}?populate=*`);

  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section className="container text-center">
      {attributes.nazov && <h2 className="large">{attributes.nazov}</h2>}
      <div className="course-main">
        {attributes.foto.data ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}${attributes.foto.data.attributes.formats.small.url}`}
            alt={`${process.env.REACT_APP_BASE_URL}${attributes.foto.data.attributes.alternativeText}`}
          />
        ) : (
          <img src="../assets/Fitzekova_logo.jpg" alt="coaching" />
        )}
        <div className="course-main-text">
          {attributes.popis && (
            <div className="m-0 p-0">
              <ReactMarkdown>{attributes.popis}</ReactMarkdown>
            </div>
          )}
        </div>
        <div className="course-info">
          <div>
            <Link
              to={`/register/${id}`}
              state={{ initialCourse: `${id}` }}
              className="btn btn-small project-link"
            >
              Prihlásiť sa
            </Link>
          </div>
          <div>
            {attributes.cena && (
              <p className="lead text-white">
                Cena: <span>{attributes.cena} EUR</span>
              </p>
            )}
            <div>
              <p className="lead text-white">Miesto / Termín: </p>
              {attributes.miesto &&
                attributes.miesto.map((location) =>
                  location.termin === null ? (
                    <div className="lead text-white" key={location.id}>
                      {location.miesto}
                    </div>
                  ) : (
                    <div className="lead text-white" key={location.id}>
                      {location.miesto}: {location.termin}
                    </div>
                  )
                )}
            </div>

            {attributes.cena && (
              <p className="lead text-white">
                Kapacita: <span>{attributes.kapacita}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      {attributes.obsah && (
        <section className="container text-center">
          <h2 className="large">Obsah kurzu</h2>

          <ReactMarkdown>{attributes.obsah}</ReactMarkdown>
        </section>
      )}
      <section className="container text-center">
        <h2 className="large"> Ďalšie informácie</h2>
        {attributes.cielova_skupina && (
          <div className="my-2">
            <h3>Cieľová skupina</h3>
            <ReactMarkdown>{attributes.cielova_skupina}</ReactMarkdown>
          </div>
        )}
        {attributes.certifikat && (
          <div className="my-2">
            <h3>Certifikát</h3>
            <ul>
              <li>{attributes.certifikat}</li>
            </ul>
          </div>
        )}
        {attributes.poznamka && (
          <div className="my-2">
            <h3>Poznámka k cene</h3>
            <ul>
              <li>{attributes.poznamka}</li>
            </ul>
          </div>
        )}
        <div className="mb-4">
          <h3>Zaujíma vás ešte niečo?</h3>
          <ul>
            <li>
              <a
                href="mailto:fitzekova@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Kontaktujte nás!
              </a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  );
}
