import React from "react";

export default function ContactFormSuccess() {
  return (
    <div className="container text-center vh50 flex-center">
      <h3 className="large">
        Vaša prihláška bola úspešne odoslaná.
        <br /> V najbližších dňoch vás budeme kontaktovať.
        <br /> Ďakujeme
      </h3>
    </div>
  );
}
