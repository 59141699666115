import React from "react";
import { Link } from "react-router-dom";

export default function Course({ courses }) {
  let asArray = [];
  if (!Array.isArray(courses) && courses) {
    asArray.push(courses);
  } else {
    asArray = courses;
  }

  if (asArray.length) {
    asArray.sort(function (a, b) {
      return (
        new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt)
      );
    });
  }

  return asArray.map((course) => (
    <div className="project" key={course.id}>
      {course.attributes.foto.data ? (
        <div className="project-image">
          <img
            src={`${process.env.REACT_APP_BASE_URL}${course.attributes.foto.data.attributes.formats.small.url}`}
            alt=""
          />
        </div>
      ) : (
        <div className="project-image">
          <h2 className="course-name">{course.attributes.nazov}</h2>
          <img
            src="./assets/Fitzekova_logo.jpg"
            alt="coaching"
            className="zindex"
          />
        </div>
      )}

      <div className="project-text">
        <div className="project-text-wrap">
          <h2 className="large">{course.attributes.nazov}</h2>
        </div>
        <div className="project-links-wrap">
          <Link
            to={`/course/${course.id}`}
            className="btn btn-small project-link"
          >
            Viac Info
          </Link>
          <Link
            to={`/register/${course.id}`}
            state={{ initialCourse: `${course.id}` }}
            className="btn btn-small project-link"
          >
            Prihlásiť sa
          </Link>
        </div>
      </div>
    </div>
  ));
}
