import React from "react";

export default function Policy() {
  return (
    <div className="container text-center">
      <h2 className="large">Pravidlá ochrany osobných údajov</h2>
      <p>
        Tieto pravidlá nakladania s osobnými údajmi Mgr. Viera Veronika
        Fitzeková – Prima Media Prešov, Mukačevská 14420/30, 080 01 Prešov,
        zapísaná v Živnostenskom registri Okresného úradu v Prešove,
        OU-PO-OZP1-2018/046979-2, číslo živnostenského registra 707-20441,
        upravujú zásady spracovania osobných údajov návštevníkov webovej stránky
        www.veronikafitzekova.sk (ďalej len „webová stránka“) najmä jej
        registrovaných užívateľov a zákazníkov Mgr. Viera Veronika Fitzeková –
        Prima Media Prešov, ktorí urobia prostredníctvom webovej stránky
        objednávku (ďalej len „zákazník“). <br /> <br />
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov pôsobí pri spracovaní
        osobných údajov ako správca a určuje tak, za akým účelom a akými
        prostriedkami bude dochádzať k spracovaniu osobných údajov. <br />{" "}
        <br />
        <strong>Zákonný dôvod spracovania osobných údajov</strong>
        <br />
        <br />
        Zákonným dôvodom spracovania vašich osobných údajov je váš súhlas dávaný
        týmto Mgr. Viera Veronika Fitzeková – Prima Media Prešov v zmysle čl. 6
        ods. 1 písm. a) Nariadenia Európskeho parlamentu a Rady 2016/679 o
        ochrane fyzických osôb pri spracovaní osobných údajov a Zákona o ochrane
        osobných údajov 18/2018 Z.z. (ďalej len „GDPR“).
        <br />
        Aké údaje spracúvame?
        <ul>
          <li>
            Údaje poskytnuté zákazníkmi. Mgr. Viera Veronika Fitzeková – Prima
            Media Prešov spracováva osobné údaje o užívateľoch a zákazníkoch.
            Primárne sú spracovávané osobné údaje, ktoré Mgr. Viera Veronika
            Fitzeková – Prima Media Prešov sami poskytnete pri vytvorení vašej
            objednávky. Tieto údaje zahŕňajú najmä e-mailovú adresu a v prípade
            zákazníkov aj meno a priezvisko, telefónne číslo. Vyššie uvedené
            údaje ďalej nazývame „osobné údaje“.{" "}
          </li>
          <li>
            Údaje získané prostredníctvom cookies. Pre lepšie cielenie
            reklamných kampaní a vylepšovanie ponuky produktov a služieb
            využívame informácie o kurzoch, ktoré naši zákazníci zakúpili. Ak
            ste vo vašom prehliadači povolili ukladanie cookies a súhlasíte s
            ich používaním, získava Mgr. Viera Veronika Fitzeková – Prima Media
            Prešov údaje o návšteve webovej stránky, prezeraných produktoch a
            ďalšej aktivite na webovej stránke.{" "}
          </li>
          <li>
            Údaje z Facebooku. Ak pre registráciu využijete sociálnu sieť
            Facebook, umožníte tiež prístup predovšetkým k vášmu menu,
            priezvisku, e-mailovej adrese a ďalším informáciám, ktoré sa s nami
            rozhodnete zdieľať.{" "}
          </li>
          <li>
            Mgr. Viera Veronika Fitzeková – Prima Media Prešov nezbiera ani inak
            nespracováva akékoľvek citlivé osobné údaje zahrňujúce napr. údaje o
            vašom zdravotnom stave, vyznania alebo viery a pod.{" "}
          </li>
        </ul>
        <br />
        <strong>Na aké účely údaje spracúvame?</strong>
        <br /> <br />
        Pri vytvorení objednávky, pri registrácii a pre poskytovanie ďalších
        služieb na veronikafitzekova.sk používame vaše osobné údaje v rozsahu:
        meno, priezvisko, e-mail a telefónne číslo. Tieto údaje bezpodmienečne
        potrebujeme k plneniu zmluvy, napr. zaslanie prístupu do konta, zaslanie
        objednávky kurzu. Taktiež budeme potrebovať informáciu o tom, akým
        spôsobom svoju objednávku zaplatíte. Po každom uskutočnenom
        nákupe/objednávke zaznamenávame informácie týkajúce sa nákupu/objednávky
        (tzv. históriu nákupov). Spracovanie údajov v tomto prípade zahŕňa
        všetky činnosti od zaevidovania objednávky, jej spracovania, vrátane
        zaplatenia a jej doručenia. Ak údaje nie sú poskytnuté, nemôže Mgr.
        Viera Veronika Fitzeková – Prima Media Prešov vybaviť vašu objednávku.
        <br />
        Prevencia podvodného konania a plnenie právnych povinností. V niektorých
        prípadoch spracovávame Vaše osobné údaje, aby sme splnili zákonné
        požiadavky. Mgr. Viera Veronika Fitzeková – Prima Media Prešov dbá na
        zabezpečenie vysokej ochrany osobných údajov a usilujeme o to, aby naši
        zákazníci mohli využívať naše služby bez obáv o ich bezpečnosť. Z toho
        dôvodu sledujeme akým spôsobom sú naše služby používané, aby sme boli
        schopní odhaliť zneužívanie či prípadné podvody realizované na stránke
        veronikafitzekova.sk. V niektorých prípadoch spracovávame vaše osobné
        údaje, aby sme splnili zákonné požiadavky. Pre uvedený účel spracovávame
        osobné údaje na základe právneho titulu oprávneného záujmu a na základe
        právneho titulu plnenia právnej povinnosti. <br />
        Pre ponuku našich produktov a propagáciu veronikafitzekova.sk formou
        obchodných oznámení (newslettrov) zasielaných užívateľom využíva Mgr.
        Viera Veronika Fitzeková – Prima Media Prešov predovšetkým e-mailovú
        adresu. Spracovanie vykonávame z dôvodu realizácie oprávnených záujmov
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov. Spracovanie prebieha
        v tomto prípade len na základe vášho súhlasu, ktorý je úplne dobrovoľný,
        a aj v prípade jeho neudelenia môžete naďalej plne využívať našu webovú
        stránku. Súhlas Mgr. Viera Veronika Fitzeková – Prima Media Prešov
        udeľujete v priebehu registrácie na veronikafitzekova.sk,
        v objednávkovom, prípadne v nákupnom procese, alebo neskôr v nastavení
        vášho užívateľského konta. Upozorňujeme, že registráciu na
        veronikafitzekova.sk môžu vykonávať iba osoby staršie ako 16 rokov. Váš
        súhlas môžete kedykoľvek odvolať kliknutím na príslušný odkaz umiestnený
        v každom obchodnom oznámení alebo v nastavení užívateľského účtu.
        <br />
        Pre marketingové účely, lepšie cielenie reklamy a propagácie spracúva
        veronikafitzekova.sk pri využití cookies údaje o návšteve webovej
        stránky, prezeraných produktoch a ďalšej aktivite na webovej stránke.
        Spracovanie je možné len na základe vášho súhlasu s používaním
        príslušných cookies. Spravidla na základe uvedených dát získava Mgr.
        Viera Veronika Fitzeková – Prima Media Prešov štatistiky, analýzy a
        reporty o správaní užívateľov webovej stránky. Na ich základe je potom
        možné lepšie zacieliť reklamu alebo prispôsobiť obsah webovej stránky
        tomu, čo vás skutočne zaujíma a čo hľadáte, alebo čo zaujíma všeobecne
        väčšiu skupinu užívateľov. V tomto prípade je spracovanie osobných
        údajov spravidla automatizované.
        <br />
        Na informovanie o dôležitých zmenách na stránke veronikafitzekova.sk – v
        prípade potreby vás upozorníme na dôležité zmeny týkajúce sa služieb,
        ktoré veronikafitzekova.sk poskytuje. Na uvedené účely spracovávame
        osobné údaje na základe právneho titulu oprávneného záujmu.
        <br />
        Pre zaistenie zákazníckej podpory využívame údaje o vašom mene a
        e-maile, ktoré vyplníte počas chatu s pracovníkom Mgr. Viera Veronika
        Fitzeková – Prima Media Prešov do príslušného chatovacieho okna na
        webovej stránke veronikafitzekova.sk. Vami uvedené meno a e-mailovú
        adresu len na základe chatu nijako ďalej nevyužívame.
        <br />
        Spracovávanie na základe súhlasu – v špecifických prípadoch, ktoré nie
        sú zahrnuté v predošlých bodoch, môžeme vyžadovať vaše osobné údaje na
        základe vášho súhlasu. V takom prípade sa účel a ďalšie informácie
        týkajúce sa takéhoto spracovávania dozviete v momente, keď taký súhlas
        budeme od vás vyžadovať.
        <br />
        <br />
        <strong> Aké sú Vaše práva?</strong>
        <br />
        <br />
        V súvislosti so spracovaním osobných údajov sa môžete obrátiť na Mgr.
        Viera Veronika Fitzeková – Prima Media Prešov a požadovať:
        <br />
        <ul>
          <li>
            Informácie ohľadom osobných údajov, ktoré Mgr. Viera Veronika
            Fitzeková – Prima Media Prešov spracováva, o účele a povahe
            spracovávania osobných údajov, vrátane informácie o prípadných
            príjemcoch osobných údajov mimo Mgr. Viera Veronika Fitzeková –
            Prima Media Prešov. Všeobecné informácie o činnostiach spracovaní
            osobných údajov sú obsiahnuté v týchto pravidlách.
          </li>
          <li>
            Prístup k údajom, ktoré ste poskytli Mgr. Viera Veronika Fitzeková –
            Prima Media Prešov, či už v priebehu registrácie, alebo vytvorení
            objednávky. V prípade uplatnenia tohto práva vám Mgr. Viera Veronika
            Fitzeková – Prima Media Prešov potvrdí, či a aké konkrétne osobné
            údaje sú spracovávané a prípadne vám budú tieto údaje sprístupnené
            spolu s informáciami o ich spracovaní.
          </li>
          <li>
            Prenesenie automatizovane spracovávaných osobných údajov získaných
            na základe vášho súhlasu od Mgr. Viera Veronika Fitzeková – Prima
            Media Prešov k inému subjektu, kedy Mgr. Viera Veronika Fitzeková –
            Prima Media Prešov odovzdá vaše osobné údaje v bežne používanom
            formáte vám alebo inému správcovi podľa vášho želania.
          </li>
          <li>
            Opravu osobných údajov, ak sú akokoľvek nepresné alebo neúplné. Iba
            v prípade aktuálnych údajov môže Mgr. Viera Veronika Fitzeková –
            Prima Media Prešov správne vybaviť vašu objednávku.
          </li>
          <li>
            Vysvetlenie a odstránenie nevyhovujúceho stavu (napr. blokáciu,
            opravu, doplnenie alebo likvidáciu osobných údajov), ak sa
            domnievate, že Mgr. Viera Veronika Fitzeková – Prima Media Prešov
            spracováva osobné údaje v rozpore s ochranou vášho osobného a
            súkromného života alebo v rozpore s právnymi predpismi.
          </li>
          <li>
            Právo byť zabudnutý, t.j. výmaz osobných údajov alebo ich obmedzené
            spracovanie, ak už nie sú potrebné na uvedené účely, alebo ak už
            Mgr. Viera Veronika Fitzeková – Prima Media Prešov nemá zákonný
            dôvod osobné údaje spracovávať, vrátane prípadov, keď s ich ďalším
            spracovaním nesúhlasíte. V rámci splnenia uvedených podmienok Mgr.
            Viera Veronika Fitzeková – Prima Media Prešov vaše údaje úplne alebo
            čiastočne zlikviduje.
          </li>
        </ul>
        Užívatelia aj zákazníci
        <br />
        veronikafitzekova.sk môžu kedykoľvek zrušiť odoberanie obchodných
        oznámení (newslettrov) a to:
        <br />
        <ul>
          <li>
            kliknutím na príslušný odkaz umiestnený v päte každého obchodného
            oznámenia
          </li>
          <li>prostredníctvom „Žiadosti dotknutejosoby – GDPR“</li>
          <li>
            namietať proti spracovaniu osobných údajov u Mgr. Viera Veronika
            Fitzeková – Prima Media Prešov na e-mailovej adrese
            fitzekova@gmail.com, prípadne prostredníctvom ďalších kontaktov
            uvedených v týchto pravidlách.
          </li>
        </ul>
        Ďalej môžu užívatelia a zákazníci veronikafitzekova.sk vzniesť námietku
        proti spracovaniu osobných údajov v prípade vyhodnocovania nákupných
        preferencií, na základe ktorej Mgr. Viera Veronika Fitzeková – Prima
        Media Prešov okamžite ukončí spracovanie osobných údajov pre tieto
        účely.
        <br />
        Pokiaľ chcete na svojom zariadení zakázať ukladanie cookies, môžete
        zmeniť nastavenia priamo vo svojom prehliadači. V prípade, že zakážete
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov ukladanie cookies,
        nemusia niektoré časti webových stránok pracovať správne.
        <br />
        Okrem vyššie uvedeného oprávnenia máte vždy v prípade porušenia
        povinností Mgr. Viera Veronika Fitzeková – Prima Media Prešov možnosť
        podať sťažnosť na Úrad pre ochranu osobných údajov.
        <br />
        <br />
        <strong>Kto má prístup k údajom?</strong>
        <br />
        <br />
        Osobné údaje sú v prvom rade spracované zamestnancami Mgr. Viera
        Veronika Fitzeková – Prima Media Prešov a spolupracujúcimi osobami.
        Všetky osoby, ktoré majú prístup k osobným údajom sú zaviazané k
        mlčanlivosti a tento záväzok trvá aj po skončení ich spolupráce s Mgr.
        Viera Veronika Fitzeková – Prima Media Prešov.
        <br />
        Verejnoprávne orgány, u ktorých platí výnimka z povinnosti mlčanlivosti.
        Ide o vybrané subjekty verejnej správy a iné subjekty, ktoré sú na to
        oprávnené zo zákona (napr. súdy).
        <br />
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov ďalej ako správca
        poveruje spracovaním osobných údajov ďalšie subjekty, ako tzv.
        Spracovateľa. Spracovateľom je každý orgán, ktorý spracúva osobné údaje
        pre Mgr. Viera Veronika Fitzeková – Prima Media Prešov na účely a
        spôsobom, ktoré Mgr. Viera Veronika Fitzeková – Prima Media Prešov
        stanovuje. V prípade, keď je so spracovaním vyžadovaný váš súhlas,
        odovzdávame údaje spracovateľom iba v prípade, že ste súhlas udelili.
        Spracovateľom odovzdávame iba tie údaje, ktoré nevyhnutne potrebujú na
        zabezpečenie svojich služieb.
        <br />
        Medzi Spracovateľov, ktorých Mgr. Viera Veronika Fitzeková – Prima Media
        Prešov využíva, patria:
        <ul>
          <li>Služby zabezpečujúce účtovníctvo</li>
          <li>Služby správy serveru a databáz</li>
          <li>Služby na správu a odosielanie emailov</li>
          <li>Služby zabezpečujúce dátovú analytiku a štatistiku</li>
        </ul>
        <br />
        <strong>Ako dlho údaje spracovávame?</strong>
        <br />
        <br />
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov spracováva osobné
        údaje na účely vykonávania zmluvy po celú dobu vybavovania objednávky,
        prípadne jej zaplatenia. Zo zákona Mgr. Viera Veronika Fitzeková – Prima
        Media Prešov následne uchováva niektoré údaje obsiahnuté v účtovných
        dokladoch.
        <br />
        Údaje získané na marketingové účely prostredníctvom cookies spracováva
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov po celú dobu trvania
        súhlasu s používaním cookies, t.j. po dobu, kedy ukladanie cookies
        povolíte vo svojom prehliadači, prípadne do doby, než je vznesená
        námietka proti spracovaniu vašich údajov za týmto účelom.
        <br />
        Ak spracúvame vaše osobné údaje na základe oprávneného záujmu, trvá
        spracúvanie po dobu, kedy trvá náš oprávnený záujem. Proti spracúvaniu
        vašich osobných údajov, ktoré prebieha na základe právneho titulu
        oprávneného záujmu, môžete kedykoľvek podať námietku.
        <br />
        <br />
        <strong>Vaše osobné údaje</strong>
        <br />
        <br />
        spracúvame a uchováme len tak dlho, ako je nevyhnutné na účely, ktoré sú
        uvedené vyššie, alebo po dobu, po ktorú je to nevyhnutné na splnenie
        povinností vyplývajúcich z právnych predpisov, ktoré sa na
        veronikafitzekova.sk vzťahujú. Po uplynutí tejto doby sa osobné údaje
        vymažú alebo anonymizujú.
        <br />
        <br />
        <strong>Bezpečnosť</strong>
        <br />
        <br />
        Mgr. Viera Veronika Fitzeková – Prima Media Prešov dbá na bezpečnosť
        vašich údajov. Nakladanie s osobnými údajmi prebieha plne v súlade s
        platnými právnymi predpismi, vrátane všeobecného nariadenia o ochrane
        osobných údajov (GDPR). Mgr. Viera Veronika Fitzeková – Prima Media
        Prešov kladie pri spracovaní osobných údajov veľký dôraz na technické aj
        organizačné zabezpečenie spracúvaných údajov.
        <br />
        Spracovanie osobných údajov prebieha v elektronickej alebo papierovej
        forme, automatizovane v elektronických informačných systémoch prípadne
        aj manuálne. Všetky osobné údaje v elektronickej forme sú uložené v
        databázach a systémoch, ku ktorým majú prístup iba osoby, ktoré
        potrebujú s osobnými údajmi bezprostredne nakladať na účely uvedené v
        týchto pravidlách, a to len v nevyhnutnom rozsahu. Prístup k týmto
        osobným údajom je chránený heslom a firewallom. Zabezpečenie osobných
        údajov je zo strany Mgr. Viera Veronika Fitzeková – Prima Media Prešov
        pravidelne testované a ochranu priebežne vylepšujeme.
        <br />
        Ochrana osobných údajov detí je pre nás mimoriadne dôležitá. Z tohto
        dôvodu Mgr. Viera Veronika Fitzeková – Prima Media Prešov vedome
        nevyberá ani nepožaduje osobné informácie od žiadnej osoby mladšej ako
        16 rokov. V prípade, že sa dozvieme, že sme zhromaždili osobné údaje od
        dieťaťa mladšieho ako 16 rokov, vymažeme tieto informácie čo najskôr.
        Deti vo veku do 16 rokov môžu služby veronikafitzekova.sk využívať len s
        povolením a dohľadom svojich rodičov.
        <br />
        <br />
        <strong>Zmeny pravidiel ochrany osobných údajov</strong>
        <br />
        <br />
        Všetky zmeny v týchto pravidlách ochrany osobných údajov sú účinné
        momentom zverejnenia na tejto stránke. Pri zmene pravidiel, ktoré by
        mohli mať významný vplyv na vaše súkromie vás upozorníme e-mailom
        a/alebo upozornením na našich stránkach. Odporúčame, aby ste informácie
        o spracovaní osobných údajov čítali pravidelne, a boli tak informovaní o
        tom, ako spracovávame vaše osobné údaje a ako môžete chrániť svoje
        súkromie.
        <br />
        Tieto pravidlá boli naposledy zmenené dňa 25.5.2022.
      </p>
    </div>
  );
}
