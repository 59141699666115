import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer() {
  return (
    <footer className="container">
      <div className="footer-content">
        <div className="footer-logo flex-center">
          <Link to="/#section-a" smooth>
            <div className="logo">
              <div className="circle">
                <div className="circle-outer">
                  <div className="circle-inner"></div>
                </div>
              </div>
            </div>
          </Link>

          <p className="lead">
            Copyright &copy; <span id="year"></span> All Rights Reserved
          </p>
        </div>
        <div className="social flex-center">
          <a href="mailto:fitzekova@gmail.com" target="_blank" rel="noreferrer">
            <i className="fas fa-envelope"></i>
          </a>
          <a
            href="https://www.facebook.com/Veronika-Fitzekov%C3%A1-460407251171718/?ref=pages_you_manage"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/veronika-fitzekova-7ba78a85/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="tel:+421908994585" target="_blank" rel="noreferrer">
            <i className="fa fa-phone"></i>
          </a>
          <Link to="/contactinfo">
            <i className="fa fa-address-card"></i>
          </Link>
        </div>
      </div>
    </footer>
  );
}
