import React from "react";
import Header from "./Header";
import Courses from "../courses/Courses";
import AboutMe from "./AboutMe";
import References from "../references/References";

export default function Main() {
  return (
    <>
      <Header />
      <Courses />
      <AboutMe />
      <References />
    </>
  );
}
