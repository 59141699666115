import React from "react";

import useFetch from "../../hooks/useFetch";
import Spinner from "../sections/Spinner";

import Reference from "./Reference";

export default function References() {
  const { loading, error, estate } = useFetch(`/api/references?populate=*`);
  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section id="section-d" className="container text-center">
      <h2 className="x-large">Referencie</h2>
      <div className="references-wrapper">
        {estate.map((reference) => (
          <Reference reference={reference} key={reference.id} />
        ))}
      </div>
    </section>
  );
}
