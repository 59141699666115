import React from "react";
import useFetch from "../../hooks/useFetch";
import Spinner from "../sections/Spinner";
import Course from "./Course";

export default function Courses() {
  const { loading, error, estate } = useFetch(
    `/api/categories?populate[kurzs][populate][0]=foto&sort=id:asc`
  );
  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section id="section-b">
      {estate.map((courses) => (
        <div className="container text-center" key={courses.id}>
          {courses.attributes.kurzs.data.length ? (
            <>
              <h2 className="x-large">{courses.attributes.nazov}</h2>
              <div className="project-wrapper">
                <Course courses={courses.attributes.kurzs.data} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ))}
    </section>
  );
}
