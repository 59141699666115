import React from "react";

export default function ContactFormFailure() {
  return (
    <div className="container text-center vh50 flex-center">
      <h3 className="large">
        Ospravedlňujeme sa, pri odosielaní prihlášky nastal problém. Prosím
        kontaktujte nás priamo prostredníctvom emailu, uvedeného na našich
        stránkach
      </h3>
    </div>
  );
}
