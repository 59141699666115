import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// Components
import Spinner from "../sections/Spinner";

//Material UI
import { styled } from "@mui/material/styles";
import useFetch from "../../hooks/useFetch";
import {
  TextField,
  Box,
  FormControlLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";

const CssTextField = styled(TextField)({
  "&:after": {
    borderBottomColor: "#ffffff",
  },
  "& .MuiSvgIcon-root": {
    color: "#ffffff",
  },
  "& label.Mui-focused": {
    color: "#ffffff",
  },
  "& label": {
    color: "#ffffff",
  },
  "& icon": {
    fill: "#ffffff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "rgb(234, 59, 47)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
    color: "#ffffff",
  },
});

export default function ContactForm() {
  const navigate = useNavigate();
  const { loading, error, estate } = useFetch(
    `/api/kurzs?fields=nazov&populate=miesto`
  );
  const initial = useLocation();
  const { initialCourse } = initial.state;
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_HEROKU;

  const [course, setCourse] = useState(initialCourse);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [location, setLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [organization, setOrganization] = useState("");
  const [notes, setNotes] = useState("");
  const [checkedC, setCheckedConditions] = useState(false);
  const [checkedM, setCheckedMarketing] = useState(false);

  useEffect(() => {
    let selectedCourse = estate.find(
      (selected) => selected.id === Number(course)
    );
    if (selectedCourse) {
      setSelectedLocation(selectedCourse.attributes.miesto);
      setSelectedCourse(selectedCourse.attributes.nazov);
    }
  }, [estate, course]);

  const handleSubmit = async (e) => {
    let data = {
      kurz: selectedCourse,
      termin_miesto: location,
      meno: firstName,
      priezvisko: secondName,
      email: email,
      telefon: mobile,
      organizacia: organization,
      poznamka: notes,
      obchodne_podmienky: checkedC,
      marketing: checkedM,
    };

    e.preventDefault();
    try {
      await axios.post("/api/forms", { data });
      navigate("/contactformsuccess");
    } catch (error) {
      console.log(error);
      navigate("/contactformfailure");
    }
  };

  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section id="section-d" className="contact-form text-center">
      <h2 className="large">Registračný formulár</h2>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": {
            m: 1,
            width: { xs: 310, sm: 550, md: 850 },
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        noValidate={false}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="flex-center flex-col">
          <CssTextField
            id="custom-css-outlined-input"
            select
            required
            label="Výber kurzu"
            value={course}
            defaultValue={course}
            onChange={(e) => setCourse(e.target.value)}
            helperText="Prosím zvolte kurz"
          >
            {estate.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes && option.attributes.nazov}
              </MenuItem>
            ))}
          </CssTextField>
          <CssTextField
            id="custom-css-outlined-input"
            select
            required
            label="Výber termínu a miesta"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            helperText="Prosím zvolte termín a miesto"
          >
            {selectedLocation.map((option) => (
              <MenuItem
                key={option.id}
                value={`${option.miesto}: ${option.termin}`}
              >
                {option.termin
                  ? `${option.miesto}: ${option.termin}`
                  : `${option.miesto}`}
              </MenuItem>
            ))}
          </CssTextField>
          <CssTextField
            required
            label="Meno"
            fullWidth
            value={firstName}
            id="custom-css-outlined-input"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <CssTextField
            required
            label="Priezvisko"
            fullWidth
            value={secondName}
            id="custom-css-outlined-input"
            onChange={(e) => setSecondName(e.target.value)}
          />

          <CssTextField
            required
            label="Email"
            type="email"
            fullWidth
            value={email}
            id="custom-css-outlined-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <CssTextField
            required
            label="Telefón"
            fullWidth
            value={mobile}
            id="custom-css-outlined-input"
            onChange={(e) => setMobile(e.target.value)}
          />
          <CssTextField
            label="Organizácia/firma"
            fullWidth
            id="custom-css-outlined-input"
            onChange={(e) => setOrganization(e.target.value)}
          />
          <CssTextField
            label="Poznámka"
            multiline
            rows={4}
            fullWidth
            value={notes}
            id="custom-css-outlined-input"
            onChange={(e) => setNotes(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedC}
                required
                onChange={(e) => setCheckedConditions(!checkedC)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label={
              <Link to={"/policy"}>
                <span className="small underline">
                  Súhlasím so spracovaním osobných údajov
                </span>
              </Link>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedM}
                onChange={(e) => setCheckedMarketing(!checkedM)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label={
              <Link to={"/policy"}>
                <div className="small">Chcem odoberať novinky</div>
              </Link>
            }
          />
        </div>
        <div className="my-3">
          <button type="submit" className="btn">
            Odoslať
          </button>
        </div>
      </Box>
    </section>
  );
}
