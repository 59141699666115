import React from "react";

export default function Reference({ reference }) {
  return (
    <div className="references">
      <div className="image-container">
        <img
          src={`${process.env.REACT_APP_BASE_URL}${reference.attributes.foto.data.attributes.formats.thumbnail.url}`}
          alt={`${process.env.REACT_APP_BASE_URL}${reference.attributes.foto.data.attributes.alternativeText}`}
        />
      </div>
      <div>
        <h2 className="lead m">{reference.attributes.meno}</h2>
        <h3 className="lead m-0">{reference.attributes.organizacia}</h3>
        <h3 className="normal italic m-0">{reference.attributes.kurz}</h3>
      </div>
      <p className="my">{reference.attributes.text}</p>
    </div>
  );
}
