import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/sections/Navbar";
import Main from "./components/sections/Main";
import Footer from "./components/sections/Footer";
import CourseDetails from "./components/courses/CourseDetails";
import ContactForm from "./components/forms/ContactForm";

import Policy from "./components/sections/Policy";
import ContactFormSuccess from "./components/forms/ContactFormSuccess";
import ContactFormFailure from "./components/forms/ContactFormFailure";
import NotFound from "./components/sections/NotFound";

import "./App.css";
import ContactInfo from "./components/sections/ContactInfo";
import CookiesInfo from "./components/sections/CookiesInfo";

function App() {
  return (
    <>
      <Router>
        <div className="content-wrap">
          <Navbar />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route exact path="/" element={<Main />} />
            <Route exact path="/course/:id" element={<CourseDetails />} />
            <Route exact path="/register/:id" element={<ContactForm />} />
            <Route
              exact
              path="/contactformsuccess"
              element={<ContactFormSuccess />}
            />
            <Route
              exact
              path="/contactformfailure"
              element={<ContactFormFailure />}
            />
            <Route exact path="/policy" element={<Policy />} />
            <Route exact path="/contactinfo" element={<ContactInfo />} />
          </Routes>
        </div>
        <Footer />
        <CookiesInfo />
      </Router>
    </>
  );
}

export default App;
