import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import ReactGA from "react-ga4";

export default function CookiesInfo() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      try {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  return (
    <>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="Súhlasím"
        declineButtonText="Nesúhlasím"
        disableStyles={true}
        buttonClasses="btn btn-small btn-green"
        buttonStyle={{
          border: "2px solid green",
          width: "7rem",
          margin: "1rem",
        }}
        declineButtonClasses="btn btn-small"
        declineButtonStyle={{
          border: "2px solid red",
          width: "7rem",
          margin: "1rem",
        }}
        containerClasses="cookies-box border"
      >
        <div className="m-2 normal">
          <div>
            <strong>
              Na zlepšenie našich služieb používame súbory “cookies”.
            </strong>
          </div>
          <div className="my">
            Nevyhnutné cookies sú potrebné pre fungovanie webu a sú automaticky
            povolené.
          </div>
          <div className="my">
            Ostatne cookies nám umožňujú presnejšie analyzovať návštevnosť a
            prispôsobovať používateľské nastavenia.
          </div>
          <div className="my">
            <Link to={"/policy"}>
              <span className="underline">Viac info.</span>
            </Link>
          </div>
          <div>Súhlasíte s používaním ostantých cookies?</div>
        </div>
      </CookieConsent>
    </>
  );
}
