import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function Navbar() {
  return (
    <div id="main-header" className="container text-center">
      <nav className="navbar">
        <Link to="/#section-a" smooth>
          <div className="logo">
            <span>V</span>
            <span>E</span>
            <span>R</span>

            <div className="circle">
              <div className="circle-outer">
                <div className="circle-inner"></div>
              </div>
            </div>
            <span>N</span>
            <span>I</span>
            <span>K</span>
            <span className="space">A</span>
            <span>F</span>
            <span>I</span>
            <span>T</span>
            <span>Z</span>
            <span>E</span>
            <span>K</span>
            <span>O</span>
            <span>V</span>
            <span>Á</span>
          </div>
        </Link>
        <ul>
          <Link to="/#section-b" smooth>
            <li className="navbar-link">Kurzy</li>
          </Link>
          <Link to="/#section-c" smooth>
            <li className="navbar-link">O mne</li>
          </Link>
          <Link to="/#section-d" smooth>
            <li className="navbar-link">Referencie</li>
          </Link>
        </ul>
      </nav>
    </div>
  );
}
