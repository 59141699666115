import React from "react";

export default function Header() {
  return (
    <section id="section-a" className="container flex-center">
      <div className="about-me-header">
        <div className="about-me-photos">
          <img src="./assets/Fitzekova_foto.jpg" alt="profile" />
        </div>
        <div className="about-me-text">
          <div className="keywords flex-center">
            <div className="keyword">
              <div>
                Médiá <span>Komunikácia </span>
              </div>
              <span className="normal-keywords">Príprava textov </span>
              <span className="normal-keywords">Koučing </span>
              <span>Etiketa </span>
              <span>Školenia </span>
              <span className="normal-keywords">Firemné časopisy </span>
              <span>Tréningy </span>
              <span>Prezentačné zručnosti </span>
              <span className="normal-keywords">PR </span>
              <span>Obchodný protokol </span>
              <span>Spoločenský protokol </span>
              <span className="normal-keywords">Jazykové korektúry </span>
              <span>Komunikácia </span>
              <span>Verejné vystupovanie </span>
              <span className="normal-keywords">Príprava textov </span>
              <span>Kurzy </span>
              <span>Videonahrávky </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
