import React from "react";

export default function NotFound() {
  return (
    <div className="container text-center vh50 flex-center flex-col">
      <h3 className="large">Daná stránka nebola nájdená</h3>
      <p className="lead">
        Požadovaná URL stránka nebola nájdená na tomto serveri. Ak ste zadali
        URL manuálne, zkontrolujte prosím jej správnosť a zkúste znovu.
        <br /> Ak sa domnievate, že príčinou chyby je webový server, kontaktujte
        nás prosím .
      </p>
    </div>
  );
}
