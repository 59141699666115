import React from "react";
import useFetch from "../../hooks/useFetch";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Spinner from "../sections/Spinner";

export default function MasonryImageList() {
  const { loading, error, estate } = useFetch(`api/trainings?populate=*`);

  if (loading) {
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container my-4 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Box
      sx={{
        width: { xs: 350, sm: 450, md: 650 },
        height: 450,
        overflowY: "scroll",
      }}
    >
      <ImageList
        variant="masonry"
        sx={{
          columnCount: {
            xs: "1 !important",
            sm: "2 !important",
          },
        }}
        gap={8}
      >
        {estate[0].attributes.foto.data.map((item) => (
          <ImageListItem key={item.id}>
            {item.attributes.formats.small && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}${item.attributes.formats.small.url}?w=248&fit=crop&auto=format`}
                srcSet={`${process.env.REACT_APP_BASE_URL}${item.attributes.formats.small.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.attributes.alternativeText}
                loading="lazy"
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
